import React, { lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageTitleSetter from '../../helpers/PageTitleSetter';
import { productSelector } from '../../redux/selectors';
import { useSelector } from 'react-redux';

const HomePage = lazy(() => import('../../pages/HomePage/HomePage'));
const ProductCharacterPage = lazy(() =>
    import('../../pages/ProductCharacterPage/ProductCharacterPage')
);
const HomePage2 = lazy(() => import('../../pages/HomePage2/HomePage2'));
const CategoryPage = lazy(() =>
    import('../../pages/CategoryPage/CategoryPage')
);
const BrandPage = lazy(() => import('../../pages/BrandPage/BrandPage'));
const AboutPage = lazy(() => import('../../pages/AboutPage/AboutPage'));
const ErrorPage = lazy(() => import('../../pages/ErrorPage/ErrorPage'));
const ServicesPage = lazy(() =>
    import('../../pages/ServicesPage/ServicesPage')
);
const ContactPage = lazy(() => import('../../pages/ContactPage/ContactPage'));
const UnderConstruction = lazy(() =>
    import('../../pages/UnderConstruction/UnderConstruction')
);

function AppRouter() {
    const product = useSelector(productSelector)
    const location = useLocation()

    return (
        <Routes>
            <Route path="/" element={
                <>
                    <PageTitleSetter title={'Texnika satışı'} />
                    <HomePage />
                </>
            } />
            <Route path="/texnika-satisi" element={
                <>
                    <PageTitleSetter title={'Texnika satışı'} />
                    <HomePage />
                </>} />
            <Route exact path="/ana-sehife" element={
                <>
                    <PageTitleSetter title={'Ana səhifə'} />
                    <HomePage2 />
                </>
            } />
            <Route exact path="/haqqimizda" element={
                <>
                    <PageTitleSetter title={'Haqqımızda'} />
                    <AboutPage />
                </>
            } />
            <Route exact path="/aqroservis" element={
                <>
                    <PageTitleSetter title={'Aqroservis'} />
                    <ServicesPage />
                </>
            } />
            <Route path="/:categoryName" element={
                <>
                    <CategoryPage />
                    <PageTitleSetter title={`${location.pathname.slice(1,2).toUpperCase()}${location.pathname.slice(2)}`} />
                </>
            } />
            <Route path="/elaqe" element={
                <>
                    <PageTitleSetter title={'Əlaqə'} />
                    <ContactPage />
                </>
            } />
            <Route path="/:categoryName/:brandName/" element={<BrandPage />} />
            <Route path="/diger-xidmetler" element={<UnderConstruction />} />
            <Route
                path="/:categoryName/:brandName/:productItem/:productId"
                element={
                    <>
                        <PageTitleSetter title={product ? product.name : 'Loading'} />
                        <ProductCharacterPage />
                    </>
                }
            />

            <Route path="*" element={
                <>
                    <PageTitleSetter title={'Error'} />
                    <ErrorPage />
                </>
            } />
        </Routes>
    );
}

export default AppRouter;
