import React, { useEffect, useRef, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import LogoImg from '../../../assests/images/aqrotexnika.png';
import LogoMobile from '../../../assests/images/aqrotexnikaNew.png';
import HeaderTop from './components/HeaderTop/HeaderTop';
import styles from './Header.module.scss';
import {
  SVGPhone,
  SVGburgerMenu,
  SVGfaceBook,
  SVGinstagram,
  SVGsearch,
  SVGMobWp,
  SVGMobPhone
} from '../../../assests/icons';
import HeaderBurger from './components/HeaderBurger/HeaderBurger';
import useScreenSize from '../../../hooks/useScreenSize';

import useDebounce from '../../../hooks/useDebounce';

import useUsers from '../../../hooks/useUsers';
import {
  setInitial,
  setLastIndex,
  setProducts,
  setShowMore,
} from '../../../redux/slices/productSlice';
import { useDispatch } from 'react-redux';

function Header() {
  const dispatch = useDispatch();
  const { getProducts } = useUsers();

  const [burgerMenuShow, setBurgerMenuShow] = useState(false);

  const isMobile = useScreenSize().isMobile;
  const [showInput, setShowInput] = useState(true);
  const [searchItem, setSearchItem] = useState('');
  const handleChangeBurgerMenuShow = () => {
    setBurgerMenuShow(!burgerMenuShow);
  };
  const handleChangeInput = (e) => {
    setSearchItem(e.target.value);
    if (e.target.value) {
      dispatch(setShowMore(false));
    } else {
      dispatch(setShowMore(true));
    }
  };
  const location = useLocation().pathname;
  const debounceSearchItem = useDebounce(searchItem, 1000);

  const getSearch = (item) => {
    // getProducts({}, 0, 10, item).then((data) => {
    //   if (data.data) {
    //     dispatch(setProducts(data.data.products));
    //     dispatch(setInitial(false));
    //   }
    // });
  };
  useEffect(() => {
    if (
        location === '/' ||
        location === '/ana-sehife' ||
        location === '/texnika-satisi'
    ) {
      if (debounceSearchItem && searchItem.length) {
        // getSearch(searchItem);
        const _ = null;
        getProducts({}, _, _, searchItem).then((data) => {
          if (data.data) {
            dispatch(setProducts(data.data.products));
            dispatch(setInitial(false));
          }
        });
      } else {
        // getSearch('');
        getProducts({}, 0, 10, '').then((data) => {
          if (data.data) {
            dispatch(setLastIndex(data.data.total));

            dispatch(setProducts(data.data.products));
          }
        });
      }
    }
  }, [debounceSearchItem, location]);

  useEffect(() => {
    setShowInput(true);

    if (
        location !== '/' &&
        location !== '/ana-sehife' &&
        location !== '/texnika-satisi'
    ) {
      setShowInput(false);
    }

    setSearchItem('');
  }, [location]);
  return (
      <div className={styles.header__wrapper}>
        <HeaderTop />
        <div className={styles.header}>
          <div className={styles.header__logo}>
            <div
                onClick={handleChangeBurgerMenuShow}
                className={styles.header__burgerMenu}
            >
              <SVGburgerMenu />
            </div>
            <Link to="/ana-sehife">
              <div className={styles.logoImgContainer}>
                <img className={styles.logoImg} src={isMobile ? LogoMobile : LogoImg} alt="logo" />
              </div>
            </Link>
          </div>
          <div className={styles.header__info}>
            {isMobile ? (
                <div className={styles.socials}>
                  <Link to="https://www.facebook.com/aqrotexnika.az">
                    <SVGMobWp />
                  </Link>
                  <Link to="https://www.facebook.com/aqrotexnika.az">
                    <SVGMobPhone />
                  </Link>
                  <Link to="https://www.facebook.com/aqrotexnika.az">
                    <SVGfaceBook />
                  </Link>
                  <Link to="https://instagram.com/aqrotexnika.az">
                    <SVGinstagram />
                  </Link>
                </div>
            ) : (
                <h2>
              <span>
                <SVGPhone />
              </span>
                  +994(51)230 00 30
                </h2>
            )}
          </div>
          {showInput && !isMobile && (
              <div className={styles.header__searchBlock}>
                <div className={styles.header__search}>
                  <input
                      autoCapitalize="off"
                      type="text"
                      placeholder="Axtarış"
                      autoComplete="off"
                      value={searchItem}
                      onChange={handleChangeInput}
                  />
                  <SVGsearch />
                </div>
              </div>
          )}
        </div>
        {burgerMenuShow && (
            <HeaderBurger
                handleChangeBurgerMenuShow={handleChangeBurgerMenuShow}
            />
        )}
      </div>
  );
}

export default Header;
