import React from 'react';
import { SVGwhatsapp } from '../../../assests/icons/index';
import styles from './WhatsApp.module.scss';
export default function WhatsApp() {
  const phoneNumber = '0512300030';

  const handleClick = (e) => {
    e.preventDefault();
    const encodedMessage = encodeURIComponent('Salam');
    const cleanPhoneNumber = phoneNumber?.replace(/[^0-9+]/g, '');
    const whatsappUrl = `https://wa.me/${cleanPhoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
      <div className={styles.link}>
        <a href="https://www.whatsapp.com/" target="_blank" onClick={handleClick}>
          <SVGwhatsapp />
        </a>
      </div>
  );
}
