import React from 'react';

import styles from './HeaderBurger.module.scss';
import LogoImg from '../../../../../assests/images/aqrotexnika.png';
import {
  SVGaddCard,
  SVGuser,
  SVGbackArrow,
  SVGsearch,
  SVGepmtyWp,
  SVGfaceBook,
  SVGinstagram,
  SVGPhone,
} from '../../../../../assests/icons/';
import { mockHeaderItems } from '../../../../../mock/mockHeaderItems';

function HeaderBurger(props) {
  const { handleChangeBurgerMenuShow, burgerMenuShow } = props;

  const closeMenu = () => {
    handleChangeBurgerMenuShow();
  };

  return (
      <div onClick={closeMenu} className={styles.overlay}>
        <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={`${styles.burger__parent} ${
                burgerMenuShow ? styles.active : ''
            }`}
        >
          <div className={styles.header__burger}>
            <div
                className={styles.header__burger__back}
                onClick={handleChangeBurgerMenuShow}
            >
              <SVGbackArrow fill="#000" />
            </div>
            <div className={styles.header__logo}>
              <a href="/ana-sehife">
                <div className={styles.logoImgContainer}>
                  <img className={styles.logoImg} src={LogoImg} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className={styles.burger__menu}>
            {mockHeaderItems.map((item) => (
                <div key={item.id} className={styles.burger__menu__link}>
                  <a href={item.url}>{item.title} </a>
                </div>
            ))}

            <div className={styles.contactInfo}>
              <p>
              <span>
                <SVGPhone />
              </span>{' '}
                +994 (51) 230 00 30
              </p>
              <div className={styles.socials}>
                <a href="https://www.facebook.com/aqrotexnika.az" target="_blank">
                  <SVGfaceBook />
                </a>
                <a href="https://instagram.com/aqrotexnika.az" target="_blank">
                  <SVGinstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default HeaderBurger;
