import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topFive: [],
};

const topFiveSlice = createSlice({
  name: "topFive",
  initialState,
  reducers: {
    setTopFive: (state, action) => {
      state.topFive = action.payload;
    },
  },
});

export const { setTopFive } = topFiveSlice.actions;
export default topFiveSlice.reducer;
