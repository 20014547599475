import { useEffect } from 'react';
import './assests/styles/global.scss';
import Banner from './components/Banner/Banner';

import Layout from './components/Layout';

import AppRouter from './routers/AppRouter';
import useUsers from './hooks/useUsers';
import { useDispatch, useSelector } from 'react-redux';
import { setBanners } from './redux/slices/bannerSlice';
import { bannersSelector } from './redux/selectors';

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'bx24_form_button';
    script.setAttribute('data-skip-moving', 'true');
    script.innerHTML = `
      (function (w, d, u, b) {
        w['Bitrix24FormObject'] = b;
        w[b] = w[b] || function () {
          arguments[0].ref = u;
          (w[b].forms = w[b].forms || []).push(arguments[0]);
        };
        if (w[b]['forms']) return;
        var s = d.createElement('script');
        s.async = 1;
        s.src = u + '?' + (1 * new Date());
        var h = d.getElementsByTagName('script')[0];
        h.parentNode.insertBefore(s, h);
      })(window, document, 'https://bitrix24.prior.az/bitrix/js/crm/form_loader.js', 'b24form');

      b24form({ "id": "42", "lang": "en", "sec": "z0z7ot", "type": "button", "click": "", "presets": { "prod_name": 'Simmental', "prod_price": '5236 ₼' } });
    `;

    window.onload = () => {
      document.body.appendChild(script);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const { getBanner } = useUsers()

  useEffect(() => {
    const getBanners = async () => {
      const response = await getBanner()
      if (response.data) {
        dispatch(setBanners(response.data))
      }
    }

    getBanners()
  }, [])


  const banners = useSelector(bannersSelector)


  function getBannerTYpe(type) {
    let banner = ""
    if (type === 'left') {
      banner = banners.filter((item) => (item.is_active === 1 && item.type === 'left'))

    }
    else if (type === 'right') {
      banner = banners.filter((item) => item.is_active === 1 && item.type === 'right')

    }
    return banner[0]?.images[0]?.path

  }



  return (
      <>

        <div className="App">
          <div className="wrapper_container">
            <Banner fixed="left" bannerImg={getBannerTYpe('left')} />
            <div className="container">
              <div className="page__wrapper">
                <Layout>
                  <AppRouter />
                </Layout>
              </div>
            </div>

            <Banner fixed="right" bannerImg={getBannerTYpe('right')} />
          </div>
        </div>
      </>
  );
}

export default App;
