import { configureStore } from "@reduxjs/toolkit";
import baseApiSlice from "../slices/baseApi";
import productSlice from "../slices/productSlice";
import contactSlice from "../slices/contactSlice";
import categorySlice from "../slices/categorySlice";
import topFiveSlice from "../slices/topFiveSlice";
import servicesSlice from "../slices/servicesSlice";
import bannerSlice from "../slices/bannerSlice";
import aboutSlice from "../slices/aboutSlice";
import { setupListeners } from '@reduxjs/toolkit/query'
import { productsApi } from "../services/productsApi";

export const store = configureStore({
  reducer: {
    baseApiSlice,
    productSlice,
    contactSlice,
    categorySlice,
    topFiveSlice,
    servicesSlice,
    bannerSlice,
    aboutSlice,
    [productsApi.reducerPath]: productsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(productsApi.middleware),
});


setupListeners(store.dispatch)