import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [],
  product: null,
  productId: null,
  initial: true,
  isEmpty: false,
  lastIndex: null,
  showMore: true,
  isLoading: true,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setInitial: (state, action) => {
      state.initial = action.payload;
    },
    setIsEmpty: (state, action) => {
      state.isEmpty = action.payload;
    },
    setLastIndex: (state, action) => {
      state.lastIndex = action.payload;
    },
    setShowMore: (state, action) => {
      state.showMore = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setProducts,
  setProduct,
  setProductId,
  setInitial,
  setIsEmpty,
  setLastIndex,
  setShowMore,
  setIsLoading,
} = productSlice.actions;
export default productSlice.reducer;
