import React, { useEffect } from 'react';
import { useLocation} from 'react-router-dom';

const PageTitleSetter = ({ title }) => {
    let location = useLocation();
    useEffect(() => {
        document.title = title; // Set the title whenever the route changes
    }, [title, location]);

    return null;
};

export default PageTitleSetter