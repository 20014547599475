import { useState, useEffect } from "react";

const minWidthToMobile = 769;

const checkForDevice = (w) => w < minWidthToMobile;

const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(checkForDevice(window.innerWidth));

  useEffect(() => {
    const handlePageResized = () => {
      setIsMobile(checkForDevice(window.innerWidth));
    };

    window.addEventListener("resize", handlePageResized);
    window.addEventListener("orientationchange", handlePageResized);
    window.addEventListener("load", handlePageResized);
    window.addEventListener("reload", handlePageResized);

    return () => {
      window.removeEventListener("resize", handlePageResized);
      window.removeEventListener("orientationchange", handlePageResized);
      window.removeEventListener("load", handlePageResized);
      window.removeEventListener("reload", handlePageResized);
    };
  }, []);

  return {
    isMobile,
  };
};

export default useScreenSize;
