import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const BASE_URL = 'https://api.aqrotexnika.az/api'

// Define a service using a base URL and expected endpoints
export const productsApi = createApi({
        reducerPath: 'productsApi',
        baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
        endpoints: (builder) => ({
            getProducts: builder.query({
            query: (name) => ({
                url: `/products`,
                method: 'POST',
            }),

        }),
    }),
    })


  export const { useGetProductsQuery } = productsApi