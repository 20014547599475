export const mockHeaderItems = [
  {
    id: 1,
    url: "/ana-sehife",
    title: "Ana səhifə",
  },

  {
    id: 2,
    url: "/texnika-satisi",
    title: " Texnika satışı",
  },
  {
    id: 3,
    url: "/aqroservis",
    title: "Aqroservis",
  },

  // {
  //   id: 4,
  //   url: "/diger-xidmetler",
  //   title: "Digər xidmətlər",
  // },
  {
    id: 5,
    url: "/haqqimizda",
    title: "Haqqımızda",
  },
  // {
  //   id: 6,
  //   url: "/bloq",
  //   title: "Bloq ",
  // },
  {
    id: 7,
    url: "/elaqe",
    title: "Əlaqə",
  },
];
