import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    servicePlants: [],
    serviceRegions: [],
    serviceTypes: [],
    serviceAreas: [],
    serviceSoilTypes: [],
    serviceIrrigationTypes: [],
    serviceCombineModels: [],
    serviceDistricts: [],
    selectedItems: {},
    serviceText: null
}

const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setServicePlants: (state, action) => {
            state.servicePlants = action.payload
        },
        setServiceRegions: (state, action) => {
            state.serviceRegions = action.payload

        },
        setServiceTypes: (state, action) => {
            state.serviceTypes = action.payload

        },
        setServiceAreas: (state, action) => {
            state.serviceAreas = action.payload

        },
        setServiceSoilTypes: (state, action) => {
            state.serviceSoilTypes = action.payload

        },
        setIrrigationTypes: (state, action) => {
            state.serviceIrrigationTypes = action.payload

        },
        setCombineModels: (state, action) => {
            state.serviceCombineModels = action.payload

        },
        setServiceDistrict: (state, action) => {
            state.serviceDistricts = action.payload
        },
        setServiceText: (state, action) => {
            state.serviceText = action.payload
        }
    }
})

export const { setServiceAreas, setServiceTypes, setServiceRegions, setServicePlants, setServiceSoilTypes, setIrrigationTypes, setCombineModels, setServiceDistrict, setServiceText } = servicesSlice.actions
export default servicesSlice.reducer