import React from 'react';
import styles from '../FooterStyle.module.scss';

export default function FooterList({ header, list }) {
    return (
        <div className={styles.listComponents}>
            <h3 className={styles.header}>{header}</h3>

            <ul>
                {list?.map((item) => (
                    <li key={item?.id}>
                        <a href={item?.link}>{item?.title}</a>
                    </li>
                ))}
            </ul>
            <br />
        </div>
    );
}
